import { Component, Prop, Vue } from 'vue-property-decorator'
import Header from '@/components/header/Header.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import BasicInput from '@/components/inputs/input/BasicInput.vue'
import SelectInput from '@/components/inputs/select/SelectInput.vue'
import TextareaInput from '@/components/inputs/textarea/TextareaInput.vue'
import Button from '@/components/button/Button.vue'
import Switcher from '@/components/switcher/Switcher.vue'
import VueMarkdown from 'vue-markdown'
import { Template } from '@/domain/entities/Template'
import { productMessageTypeList } from '@/domain/value-objects/MessageTypes'
import Route from 'vue-router'
import { SwitcherOptions } from '@/components/switcher/Switcher.ts'
import Confirmation from '@/components/modals/confirmation/Confirmation.vue'
import BasicModal from '@/components/modals/basic-modal/BasicModal.vue'
import { create } from 'vue-modal-dialogs'

const ShowBasicModal = create(BasicModal, 'title', 'content')
const ShowConfirmationModal = create(Confirmation, 'title', 'content')

export type UpdateTemplateFormFieldTypes = 'subject' | 'text' | 'html'
export type UpdateTemplateFormType = {
  subject: string;
  text: string;
  html: string;
}

@Component({
  components: {
    Sidebar,
    Header,
    BasicInput,
    SelectInput,
    TextareaInput,
    Switcher,
    Button,
    VueMarkdown,
    BasicModal,
    Confirmation
  }
})
export default class UpdateTemplate extends Vue {
  private initialFormState: UpdateTemplateFormType;
  private form: UpdateTemplateFormType = {
    subject: '',
    text: '',
    html: ''
  }

  private template: Template | null = null;
  private switchStatus = true
  private templateSaved = false
  private discardChanges = false
  private switcherOptions: SwitcherOptions = {
    a: {
      title: 'Email html version',
      text: 'html/code'
    },
    b: {
      title: 'Email plain text version',
      text: 'plain text'
    }
  }

  @Prop({ required: true, type: String })
  readonly templateName: string

  get availablePlaceholders (): Array<string> {
    if (!this.template || !this.template.messageType) {
      return []
    }

    return Object.values(productMessageTypeList[this.$store.state.product])
      .find(messageType => messageType.value === this.template!.messageType)!
      .placeholders
  }

  get templateHtml (): string {
    return this.form.html.replace(/(?:\r\n|\r|\n)/g, '')
  }

  public created () {
    if (this.$store.state.template.templates.length === 0) {
      this.$store.dispatch('getTemplates').then(this.loadTemplate)
      return
    }

    this.loadTemplate()
  }

  public beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (
      this.discardChanges ||
      this.templateSaved ||
      JSON.stringify(this.initialFormState) === JSON.stringify(this.form)
    ) {
      next()
      return
    }

    this.showNoSaveModal()
    this.discardChanges = true
  }

  private async showNoSaveModal () {
    this.discardChanges = await ShowConfirmationModal(
      'Warning',
      'You have unsaved changes, please save before quitting'
    )
  }

  private setFormField (formField: UpdateTemplateFormFieldTypes, formValue: string) {
    this.form[formField] = formValue
  }

  private switching (switcher: boolean) {
    this.switchStatus = switcher
  }

  private submit () {
    let template = null
    try {
      template = new Template(
        this.$props.templateName,
        this.template!.messageType,
        this.template!.trustabilityLevel,
        this.template!.useInterval,
        this.template!.sender,
        this.template!.providerName,
        this.form.subject,
        this.form.text,
        this.form.html
      )
    } catch (e) {
      ShowBasicModal('Warning', e.message)
      return
    }

    this.templateSaved = true

    this.$store.dispatch('updateTemplate', template.toMailerRequest())
      .then(() => {
        this.$router.push({ path: '/' })
      })
  }

  private loadTemplate () {
    const template = this.$store.state.template.templates.find(
      (template: Template) => template.name === this.templateName
    )

    this.form = {
      subject: template.subject,
      text: template.text,
      html: template.html
    }

    this.initialFormState = { ...this.form }

    this.template = template
  }
}
